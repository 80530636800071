import React, { useState } from 'react';
import { Container, Col, Button, Grid, Flex } from '@mantine/core';

interface CounterProps {
  onChange: (value: number) => void;
}

const Counter: React.FC<CounterProps> = ({ onChange }) => {
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount((prevCount) => {
      const newCount = prevCount + 1;
      onChange(newCount);
      return newCount;
    });
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount((prevCount) => {
        const newCount = prevCount - 1;
        onChange(newCount);
        return newCount;
      });
    }
    // You can choose to add an alert or other feedback if count is already zero
  };

  return (
    <Flex gap={10}>
      <Button
        variant="primary"
        onClick={handleIncrement}
      >
        +
      </Button>

      <p>Count: {count}</p>

      <Button
        variant="primary"
        onClick={handleDecrement}
      >
        -
      </Button>
    </Flex>
  );
};

export default Counter;
