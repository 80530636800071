import { Button, Card, Checkbox, Flex, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import React from 'react';

import { useStyles } from './styles';
import { useLocation, useParams } from 'react-router-dom';
import { supabase } from '../../Service/supabase';
import { useQuery } from 'react-query';
import Counter from '../../components/Counter';

export default function Ticket() {
  const [ticketQuantity, setTicketQunatity] = React.useState(1);
  const { classes } = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const { data } = useQuery({
    queryKey: ['events', id],
    queryFn: async () =>
      await supabase
        .from('events')
        .select('*')
        .eq('id', id)
        .maybeSingle()
        .then(({ data }) => data),

    enabled: !location.state,
    initialData: location.state ?? undefined,
  }) as any;

  const [acceptConditions, setAcceptConditions] = React.useState(false);

  const form = useForm({
    initialValues: {
      email: '',
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  return (
    <form
      action="/api/create-checkout-session"
      method="POST"
    >
      <Flex
        direction="column"
        align="center"
        p={20}
        gap={30}
      >
        <Card
          shadow="sm"
          p={20}
          radius="md"
          className={classes.card}
        >
          <Flex
            gap={10}
            align="center"
          >
            <Text>{data?.name}</Text>
            <Text>cijena : {data?.price}e</Text>
            <Counter onChange={(value) => setTicketQunatity(value)} />
          </Flex>
        </Card>
        <Flex gap={30}>
          <Card
            shadow="sm"
            p={20}
            radius="md"
            className={classes.card}
          >
            <Card.Section>
              <Text
                size="sm"
                ta="center"
              >
                Podaci kupca
              </Text>
            </Card.Section>
            <Flex
              direction="column"
              className={classes.checkoutWrapper}
            >
              <TextInput
                label="E-mail"
                name="email"
                size="xs"
                miw={400}
                withAsterisk
                {...form.getInputProps('email')}
                // required
              />
              <TextInput
                label="Ponovi e-mail adresu"
                name="email"
                size="xs"
                miw={400}
                withAsterisk
                {...form.getInputProps('email')}
                // required
              />

              <TextInput
                label="Ime i Prezime"
                name="fullName"
                size="xs"
                miw={400}
                {...form.getInputProps('fullName')}
              />
            </Flex>
          </Card>

          <Flex
            // shadow="sm"
            // radius="md"
            direction="column"
            p={20}
            className={classes.card}
          >
            <Card.Section>
              <Text
                size="sm"
                ta="center"
              >
                Kupiti
              </Text>
            </Card.Section>
            <Flex
              direction="column"
              gap={10}
              style={{ flexGrow: '1' }}
            >
              <Text
                size="sm"
                className={classes.detailText}
              >
                Radionica test
              </Text>
              <Text
                size="sm"
                className={classes.detailText}
              >
                <strong>Datum: </strong> 12.8.1995
              </Text>
              <Text
                size="sm"
                className={classes.detailText}
              >
                <strong>Vrijeme: </strong> 20:00
              </Text>
              <Text
                size="sm"
                className={classes.detailText}
              >
                <strong>Količina:</strong> {ticketQuantity}
              </Text>
              <Text
                size="sm"
                className={classes.detailText}
              >
                <strong>Ukupno:</strong> 20€
              </Text>
              <Checkbox
                label="Prihvatio sam i slažem se s uvjetima korištenja i odredbama web stranice"
                size="xs"
                value={acceptConditions as any}
                onChange={(e) => setAcceptConditions(e.target.checked)}
                mt={30}
              />
            </Flex>

            <Button
              color="primary.0"
              variant="filled"
              type="submit"
              disabled={!acceptConditions}
              style={{ justifySelf: 'end' }}
            >
              Plaćanje
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
}
